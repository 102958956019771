<template>
  <div class="auto-evaluation">
    <!-- <div class="search-bar">
      <span class="label">状态：</span>
      <el-select v-model="search.isDisable" placeholder="请选择状态" size="small">
        <el-option v-for="item in statusOptions" :key="item.value" :label="item.label" :value="item.value"></el-option>
      </el-select>
      <el-button type="primary" size="small" class="btn" icon="el-icon-search" @click="searchList">查询</el-button>
      <el-button size="small" class="btn" icon="el-icon-refresh-left" @click="resetSearch">重置</el-button>
    </div> -->

    <!-- 后期需求变更备用 -->
    <el-button type="primary" size="small" icon="el-icon-plus" @click="processItem({}, 1)">新增规则</el-button>

    <el-table :data="tableData" style="width: 100%" class="table-ex" border :header-cell-style="{ 'background-color': '#f5f7fa' }" size="small">
      <el-table-column label="自动评价时间">
        <template slot-scope="scope">
          {{ scope.row.autoTimesUnit === 3 ? '当天' : `${scope.row.autoTimes}${scope.row.autoTimesUnit == 1 ? '小时' : '天'}` }}
        </template>
      </el-table-column>

      <el-table-column prop="tyIntegralCategoryName" label="适用门店">
        <template slot-scope="scope">
          {{ (scope.row.storeIdList || []).length || '' }}
        </template>
      </el-table-column>

      <el-table-column prop="environmentScore" label="环境整体评分"></el-table-column>
      <el-table-column prop="serviceScore" label="服务整体评分"></el-table-column>
      <el-table-column prop="serviceItemScore" label="子服务评分"></el-table-column>

      <el-table-column prop="evaluation" label="评价内容"></el-table-column>
      <el-table-column prop="createUserName" label="创建人"></el-table-column>
      <el-table-column prop="updateUserName" label="最近修改人"></el-table-column>

      <el-table-column prop="updateTime" label="最近修改时间"></el-table-column>
      <el-table-column prop="createTime" label="创建时间"></el-table-column>

      <el-table-column label="状态" width="100">
        <template slot-scope="scope">
          <el-tag :type="scope.row.isDisable == 1 ? 'danger' : 'success'">
            {{ scope.row.isDisable == 1 ? '停用' : '启用' }}
          </el-tag>
        </template>
      </el-table-column>

      <el-table-column label="是否默认规则" width="100">
        <template slot-scope="scope">
          <el-tag :type="scope.row.isDefault == 1 ? 'success' : 'danger'">
            {{ scope.row.isDefault == 1 ? '是' : '否' }}
          </el-tag>
        </template>
      </el-table-column>

      <el-table-column fixed="right" label="操作" width="160">
        <template slot-scope="scope">
          <el-button type="text" size="small" @click="processItem(scope.row, 2)">编辑</el-button>

          <el-button type="text" size="small" @click="processItem(scope.row, 3)" v-if="scope.row.isDefault != 1">
            {{ scope.row.isDisable == 1 ? '启用' : '停用' }}
          </el-button>

          <el-button type="text" size="small" @click="processItem(scope.row, 4)" v-if="scope.row.isDefault != 1">删除</el-button>
        </template>
      </el-table-column>
    </el-table>

    <!-- <div class="footer">
      <el-pagination background layout="total,prev,pager,next,sizes" :total="total" :page-sizes="[10, 50, 100, 200]" @size-change="pageSizeChange" @current-change="pageChange"></el-pagination>
    </div> -->

    <el-dialog :title="dialogTitle" :visible.sync="dialogVisible" width="700px" :close-on-click-modal="false" :close-on-press-escape="false" top="30px">
      <EditItemDialog @cancel="dialogCancel" v-if="dialogVisible" :item="currentItem" />
    </el-dialog>
  </div>
</template>

<script>
import { getAutoEvaluationList, deleteAutoEvaluation, enableAutoEvaluation, disableAutoEvaluation } from '@/api/newEvaluation';
// import StoreTable from './components/StoreTable.vue';
import EditItemDialog from './EditItemDialog.vue';

export default {
  components: {
    // StoreTable,
    EditItemDialog,
  },
  data() {
    return {
      tableData: [],
      pageNumber: 1,
      pageSize: 1000,
      total: 0,
      search: {},
      dialogVisible: false,
      dialogTitle: '自动评价规则',
      currentItem: {},
      statusOptions: [],
    };
  },
  computed: {
    searchCondition() {
      const obj = { ...this.search };

      return {
        data: obj,
        pageNumber: this.pageNumber,
        pageSize: this.pageSize,
      };
    },
  },
  mounted() {
    this.getList();
  },
  methods: {
    processItem(row, type) {
      if (type == 1 || type == 2) {
        this.dialogVisible = true;
        this.dialogTitle = type == 1 ? '新增自动评价规则' : '编辑自动评价规则';
        this.currentItem = type == 1 ? {} : { ...row };
        return;
      }

      if (type === 3) {
        this.$confirm(`确认是否${row.isDisable === 1 ? '启用' : '停用'}?`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        })
          .then(() => {
            const func = row.isDisable == 0 ? disableAutoEvaluation : enableAutoEvaluation;

            func({
              data: row.configId,
            })
              .then((res) => {
                if (res.success) {
                  this.$message.success('操作成功');
                  this.getList();
                }
              })
              .catch((err) => console.error(err));
          })
          .catch((err) => console.error(err));
      }

      if (type == 4) {
        this.$confirm(`确认是否删除?`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        })
          .then(() => {
            deleteAutoEvaluation({
              data: row.configId,
            })
              .then((res) => {
                if (res.success) {
                  this.$message.success('操作成功');
                  this.getList();
                }
              })
              .catch((err) => console.error(err));
          })
          .catch((err) => console.error(err));
      }
    },
    resetSearch() {
      Object.keys(this.search).forEach((el) => {
        this.search[el] = Array.isArray(this.search[el]) ? [] : '';
      });

      this.pageNumber = 1;
      this.getList();
    },
    getList() {
      getAutoEvaluationList(this.searchCondition)
        .then((res) => {
          // const { content, total } = res.data || {};
          // this.tableData = content || [];
          // this.total = Number(total || '0');

          this.tableData = res.data || [];
        })
        .catch((err) => console.error(err));
    },
    pageSizeChange(val) {
      this.pageSize = val;
      this.getList();
    },
    pageChange(val) {
      this.pageNumber = val;
      this.getList();
    },
    searchList() {
      this.pageNumber = 1;
      this.getList();
    },
    dialogCancel(isSubmit) {
      isSubmit && this.getList();
      this.dialogVisible = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.auto-evaluation {
  background-color: white;
  width: 100%;
  padding: 15px;
  box-sizing: border-box;

  .search-bar {
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    > :nth-child(n) {
      margin-top: 20px;
    }

    .label {
      font-size: 14px;
      margin-left: 15px;
    }

    .select {
      width: 200px;
      margin-right: 10px;
    }

    .select-max {
      width: 430px;
      margin-right: 10px;
    }

    .input {
      width: 220px;
    }

    .date-picker {
      width: 360px;
    }

    .btn {
      margin-left: 15px;
    }
  }

  .table-ex {
    margin-top: 20px;
  }

  .footer {
    display: flex;
    justify-content: flex-end;
    padding: 20px 0 0 0;
  }

  .dialog-footer {
    text-align: right;
  }
}
</style>
