<template>
  <el-form ref="EditItemForm" :model="formData" :rules="rules" v-loading="loading" label-position="right" size="small" label-width="110px">
    <el-form-item label="自动评价时间：">
      <el-radio-group v-model="autoTimesUnit">
        <el-radio :label="3">服务单完成后 当天</el-radio>
        <el-radio :label="2">
          <div class="inline-wrap">
            <span style="margin-right: 10px">服务单完成后</span>
            <el-input-number v-model="formData.autoTimes" :min="1" :max="99999" label="" size="small"></el-input-number>
            <el-select v-model="formData.autoTimesUnit" placeholder="选择单位" class="unit-select" size="small">
              <el-option label="小时" :value="1"></el-option>
              <el-option label="天" :value="2"></el-option>
            </el-select>
          </div>
        </el-radio>
      </el-radio-group>
      <div class="time-tips">{{ autoTimesUnit === 3 ? '服务单完成全部服务后当前还未评价的，当天23:45:00自动评价' : '服务单完成全部服务后，处于待评价状态，超过这个时间，则自动评价' }}</div>
    </el-form-item>

    <div class="form-title">服务评分</div>
    <el-form-item label="环境整体评分：" prop="">
      <el-input-number v-model="formData.environmentScore" :min="1" :max="5" label="请输入环境整体评分" size="small"></el-input-number>
    </el-form-item>

    <el-form-item label="服务整体评分：" prop="">
      <el-input-number v-model="formData.serviceScore" :min="1" :max="5" label="请输入服务整体评分" size="small"></el-input-number>
    </el-form-item>

    <el-form-item label="子服务评分：" prop="">
      <el-input-number v-model="formData.serviceItemScore" :min="1" :max="5" label="请输入子服务评分" size="small"></el-input-number>
    </el-form-item>

    <h4>评价内容</h4>
    <el-form-item label="" label-width="0px">
      <el-input type="textarea" v-model="formData.evaluation" placeholder="请输入评价内容" size="small" :rows="4" :maxlength="100" show-word-limit></el-input>
    </el-form-item>

    <h4>适用门店</h4>
    <el-button size="small" icon="el-icon-plus" @click="addStore" v-if="formData.isDefault != 1">添加门店</el-button>

    <div class="store-list" v-if="storeTags.length > 0">
      <el-tag v-for="(tag, index) in storeTags" :key="tag.storeName" closable @close="removeStoreTag(index)">
        {{ tag.storeName }}
      </el-tag>
    </div>

    <StoreTable :show.sync="storeDialogVisible" @change="storeChange" :valArr="formData.storeIdList" :isSelect="0" :configId="formData.configId" />

    <div style="text-align: right; margin-top: 15px">
      <el-button type="primary" @click="submit">确 定</el-button>
      <el-button @click="$emit('cancel', false)">取 消</el-button>
    </div>
  </el-form>
</template>

<script>
import { editAutoEvaluation, getAutoEvaluationDetail } from '@/api/newEvaluation';
import StoreTable from './components/StoreTable.vue';

export default {
  components: {
    StoreTable,
  },
  props: {
    item: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      formData: {
        configId: '',
        autoTimes: 15,
        autoTimesUnit: 2,
        environmentScore: 5,
        serviceScore: 5,
        serviceItemScore: 5,
        storeIdList: [],
      },
      autoTimesUnit: 2,
      rules: {
        // vipLevelName: { required: true, message: '请输入等级名称', trigger: 'blur' },
        // vipLevel: { required: true, message: '请选择会员等级', trigger: 'change' },
        // validPeriod: { required: true, message: '请输入会员有效期', trigger: 'blur' },
        // amountDescription: { required: true, message: '请输入消费金额区间', trigger: 'blur' },
        // integralMultiple: { required: true, message: '请选择积分加速兑换', trigger: 'change' },
      },
      storeTags: [],
      storeDialogVisible: false,
      loading: false,
    };
  },
  watch: {
    item: {
      deep: true,
      immediate: true,
      handler(newVal) {
        if (newVal && newVal.configId) {
          this.getDetail();
        }
      },
    },
    storeTags: {
      deep: true,
      handler(newVal) {
        this.formData.storeIdList = (newVal || []).map((el) => el.storeId);
      },
    },
  },
  methods: {
    getDetail() {
      this.loading = true;
      getAutoEvaluationDetail({ data: this.item.configId })
        .then((res) => {
          if (res.data.autoTimesUnit === 3) {
            res.data.autoTimesUnit = 2;
            this.autoTimesUnit = 3;
          } else {
            this.autoTimesUnit = 2;
          }
          this.formData = { ...(res.data || {}) };
          this.storeTags = res.data.storeResDtoList || [];
          this.loading = false;
        })
        .catch((err) => {
          console.log(err.message || err);
          this.loading = false;
        });
    },
    submit() {
      this.$refs.EditItemForm.validate((valid) => {
        if (!valid) return;

        if (this.formData.storeIdList.length == 0 && this.formData.isDefault != 1) {
          this.$message.warning('请至少选择一个适用门店');
          return;
        }
        const data = { ...this.formData, autoTimesUnit: this.autoTimesUnit === 3 ? 3 : this.formData.autoTimesUnit };
        editAutoEvaluation({ data })
          .then((res) => {
            if (!res.success) return;
            this.$message.success('操作成功');
            this.$emit('cancel', true);
          })
          .catch((err) => console.log(err.message || err));
      });
    },
    addStore() {
      this.storeDialogVisible = true;
    },
    storeChange(data) {
      this.storeTags = [...data];
    },
    removeStoreTag(index) {
      this.storeTags.splice(index, 1);
    },
  },
};
</script>

<style lang="scss" scoped>
.time-tips {
  color: #9a9d9e;
  font-size: 12px;
  line-height: 26px;
}
.form-title {
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
}

.unit-select {
  width: 100px;
  margin: 0 10px;
}

.inline-wrap {
  display: inline-flex;
  align-items: center;
  font-size: 14px;
}

.store-list {
  > :nth-child(n) {
    margin-right: 10px;
    margin-top: 10px;
  }
}
</style>
