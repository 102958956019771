<template>
  <el-dialog title="门店列表" :visible="show" width="850px" :close-on-click-modal="false" :close-on-press-escape="false"
    top="30px" append-to-body @close="dialogClose">
    <div class="store-table">
      <div class="search-bar">

        <div class="search-item">
          <span class="label">所属城市：</span>
          <CitySelect :cityId.sync="search.cityId" ref="CitySelect" />
        </div>
        <div class="search-item">
          <span class="label">门店名称：</span>
          <el-input placeholder="请输入门店名称" size="small" v-model="search.storeName" class="input" />
        </div>
        <div class="search-item">
          <span class="label">所属经营方：</span>
          <el-input placeholder="请输入经营方" size="small" v-model="search.firmName" class="input" />
        </div>


        <el-button type="primary" size="small" class="btn" icon="el-icon-search" @click="searchList">查询</el-button>
        <el-button size="small" class="btn" icon="el-icon-refresh-left" @click="resetSearch">重置</el-button>
      </div>

      <el-table :data="tableData" style="width: 100%" class="table-ex" border
        :header-cell-style="{ 'background-color': '#f5f7fa' }" size="small" ref="Table" :row-key="(row) => row.storeId"
        @selection-change="selectionChange" max-height="400px">
        <el-table-column type="selection" width="55" reserve-selection v-if="showCheckbox"></el-table-column>
        <el-table-column prop="storeId" label="门店ID"></el-table-column>
        <el-table-column prop="storeName" label="门店名称"></el-table-column>
        <el-table-column prop="cityName" label="所属城市"></el-table-column>
        <el-table-column prop="firmName" label="所属经营方"></el-table-column>
      </el-table>

      <!-- <div class="footer">
        <el-pagination background layout="total,prev,pager,next,sizes"
          :total="total" :page-sizes="[10, 50, 100, 200]"
          @size-change="pageSizeChange" @current-change="pageChange">
        </el-pagination>
      </div> -->
    </div>

    <div style="text-align: right">
      <el-button type="primary" @click="submitStore">确 定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import CitySelect from './CitySelect.vue';

import { getStoreList } from '@/api/newEvaluation';


export default {
  components: {
    CitySelect,
  },
  props: {
    valArr: {
      type: Array,
      default: () => [],
    },
    showCheckbox: {
      type: Boolean,
      default: true,
    },
    configId: {
      type: [Number, String],
      default: '',
    },
    isSelect: {
      type: Number,
      default: 0,
    },
    show: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      objArr: [
        {
          text: '所属城市:',
          type: 'CitySelect',
          value: [],
          placeholder: '请选择城市'
        },
        {
          text: '门店名称:',
          type: 'el-input',
          value: '',
          placeholder: '请选择城市'
        },
        {
          text: '所属经营方:',
          type: 'el-input',
          value: '',
          placeholder: '请选择城市'
        }

      ],
      tableData: [],
      pageNumber: 1,
      pageSize: 10,
      total: 0,
      search: {
        storeName: '',
        cityId: '',
        firmName: ''
      },
      checkedArr: [],
    };
  },
  computed: {
    searchCondition() {
      const obj = { ...this.search, configId: this.configId, isSelect: this.isSelect };

      return {
        data: obj,
        pageNumber: this.pageNumber,
        pageSize: this.pageSize,
      };
    },
  },
  watch: {
    obj: {
      handler(n) {
        console.log(n)
      },
      deep: true,
    },
    show(newVal) {
      if (newVal) {
        this.getList();
      }
    },
    valArr: {
      deep: true,
      handler() {
        if (!this.show) return;
        this.showCheckedStoreList();
      },
    },
  },
  methods: {
    resetSearch() {
      this.$refs.CitySelect && this.$refs.CitySelect.clearAll();

      Object.keys(this.search).forEach((el) => {
        this.search[el] = Array.isArray(this.search[el]) ? [] : '';
      });

      this.pageNumber = 1;
      this.getList();
    },
    getList() {
      getStoreList(this.searchCondition)
        .then((res) => {
          //   const { content, total } = res.data || {};
          //   this.tableData = content || [];
          //   this.total = Number(total || '0');

          this.tableData = res.data || [];
          this.showCheckedStoreList();
        })
        .catch((err) => console.log(err));
    },
    pageSizeChange(val) {
      this.pageSize = val;
      this.getList();
    },
    pageChange(val) {
      this.pageNumber = val;
      this.getList();
    },
    searchList() {
      this.pageNumber = 1;
      this.getList();
    },
    selectionChange(e) {
      this.checkedArr = e || [];
    },
    submitStore() {
      this.$emit('change', [...this.checkedArr]);
      this.$emit('update:show', false);
    },
    dialogClose() {
      this.$emit('update:show', false);
    },
    showCheckedStoreList() {
      this.$nextTick(() => {
        this.$refs.Table.clearSelection();

        this.tableData
          .filter((el) => (this.valArr || []).includes(el.storeId))
          .forEach((el) => {
            this.$refs.Table.toggleRowSelection(el, true);
          });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.store-table {
  background-color: white;
  width: 100%;
  padding: 15px;
  box-sizing: border-box;

  .search-bar {
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    > :nth-child(n) {
      margin-top: 20px;
    }

    .label {
      font-size: 14px;
      margin-left: 15px;
    }

    .select {
      width: 200px;
      margin-right: 10px;
    }

    .select-max {
      width: 430px;
      margin-right: 10px;
    }

    .input {
      width: 220px;
    }

    .date-picker {
      width: 360px;
    }

    .btn {
      margin-left: 15px;
    }
  }

  .table-ex {
    margin-top: 20px;
  }

  .footer {
    display: flex;
    justify-content: flex-end;
    padding: 20px 0 0 0;
  }

  .dialog-footer {
    text-align: right;
  }

  .buttons-wrap {
    margin-top: 13px;
  }
}
</style>
