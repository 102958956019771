import request from '@/utils/request';

// 门店评价统计-导出
export function getStoreEvaluationPageListExport(data) {
  return request({
    url: 'evaluation-web-api/admin/evaluation/storeEvaluationPageListExport',
    method: 'POST',
    data,
    responseType: 'blob',
  });
}

// 员工评价统计-导出
export function getCountServiceItemAndEvaluationExport(data) {
  return request({
    url: 'order-web-api/supplier/service/serviceitems/countServiceItemAndEvaluationExport',
    method: 'POST',
    data,
    responseType: 'blob',
  });
}

// 员工评价查询
export function getEvaluationStatistics(data) {
  return request({
    url: 'order-web-api/supplier/service/serviceitems/countServiceItemAndEvaluation',
    method: 'POST',
    data,
  });
}

// 门店评价查询
export function getStoreEvaluationPageList(data) {
  return request({
    url: 'evaluation-web-api/admin/evaluation/storeEvaluationPageList',
    method: 'POST',
    data,
  });
}

export function getPostList(data) {
  return request({
    url: 'user-web-api/supplier/post/getPostList',
    method: 'POST',
    data,
  });
}

export function getAutoEvaluationList(data) {
  return request({
    url: 'evaluation-web-api/admin/evaluationAutoConfig/list',
    method: 'POST',
    data,
  });
}

export function disableAutoEvaluation(data) {
  return request({
    url: 'evaluation-web-api/admin/evaluationAutoConfig/disable',
    method: 'POST',
    data,
  });
}

export function enableAutoEvaluation(data) {
  return request({
    url: 'evaluation-web-api/admin/evaluationAutoConfig/enable',
    method: 'POST',
    data,
  });
}

export function deleteAutoEvaluation(data) {
  return request({
    url: 'evaluation-web-api/admin/evaluationAutoConfig/delete',
    method: 'POST',
    data,
  });
}

export function getStoreList(data) {
  return request({
    url: 'evaluation-web-api/admin/evaluationAutoConfig/getSelectStoreList',
    method: 'POST',
    data,
  });
}

export function editAutoEvaluation(data) {
  return request({
    url: 'evaluation-web-api/admin/evaluationAutoConfig/add',
    method: 'POST',
    data,
  });
}

export function getAutoEvaluationDetail(data) {
  return request({
    url: 'evaluation-web-api/admin/evaluationAutoConfig/detail',
    method: 'POST',
    data,
  });
}
